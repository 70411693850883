import React from 'react'
import { Banner } from './Banner'
import { Contact } from './Contact'
import { Features } from './Features'
import { Footer } from './Footer'
import { LikeOilForEngine } from './LikeOilForEngine'
import { Navbar } from './Navbar'
import { OurWorkProcess } from './OurWorkProcess'
import AppContext from '../AppContext';
import { useContext } from 'react';
import servicebnnerimg from '../Assets/images/img1.png'
import earnpointimg from '../Assets/images/img2.png'
import iconoil from '../Assets/images/oilchanges.png'
import tirerotation from '../Assets/images/tirerotations.png'
import wheelalignment from '../Assets/images/wheelalignments.png'
import tires from '../Assets/images/tries.png'
import brakepads from '../Assets/images/brakepads.png'
import batteries from '../Assets/images/batteries.png'
import airfilters from '../Assets/images/airfilters.png'
import wiperblades from '../Assets/images/wiperblades.png'
import multipoints from '../Assets/images/inspections.png'
import arrow from '../Assets/images/up-arrows.png'
import favorite from '../Assets/images/img3.png'
import vehicleapperance from '../Assets/images/img4.png'
import pffe from '../Assets/images/img5.png'
import eirp from '../Assets/images/img7.png'
import withibizipoints from '../Assets/images/img6.png'


import { DownLoadAppScetion } from './DownLoadAppScetion'

import { Loader } from './Loader'
import { Para } from './Para'
import { Product } from './Product'
import { LifeCycle } from './LifeCycle'
import Tabslayout from './TabsLayout'

export const AccountDeletionPolicy = () => {
  const myContext = useContext(AppContext);

  const servicesModel = [
    {
      icon : iconoil,
      title : 'Oil Change'
    },
    {
      icon : tirerotation,
      title : 'Tire Rotations'
    },
    {
      icon : wheelalignment,
      title : 'Wheel Alignments'
    },
    {
      icon : tires,
      title : 'Tires'
    },
    {
      icon : brakepads,
      title : 'Brake Pads'
    },
    {
      icon : batteries,
      title : 'Batteries'
    },
    {
      icon : airfilters,
      title : 'Air Filters'
    },
    {
      icon : wiperblades,
      title : 'Wiper Blades'
    },
    {
      icon : multipoints,
      title : 'Inspections'
    }
    // {
    //   icon : otherimg,
    //   title : 'Other'
    // }
  ]

  return (
    <>
      <Navbar/>
      {
        myContext.loadingstate === false &&
        <Loader/>
      }
        <div class="banner__wrapper policy-page-banner flex dir-row" id="home">
        <div class="">
            <h1 class="banner__title strong "><span>Account Deletion Policy</span> </h1><div class="flex space"></div></div>
    </div>
       <div  class="policy-page">
  <p>At IBIZI, we are committed to safeguarding your privacy and ensuring that your personal data is handled with care. If you choose to delete your account, we offer a transparent and secure process to ensure that your data is properly managed and removed from our system.</p>

  <h2 class="banner__title strong m-b-20"> <span>Steps to Delete Your Account</span> </h2>
  <p><strong>Request for Account Deletion:</strong> To initiate the account deletion process, you must submit a formal request through our support team or within the account settings of your app. Once your request is received, we will begin the process immediately.</p>
  
  <h2 class="banner__title strong m-b-20"> <span>Account Deactivation (15-Day Period):</span> </h2>
  <p>Upon receiving your deletion request, your account will be deactivated. This means:</p>
  <ul>
    <li>You will no longer have access to your account.</li>
    <li>Any ongoing activities related to your account, including notifications, will cease.</li>
    <li>Your account will remain in a deactivated state for 15 days.</li>
  </ul>
  <p>This 15-day window is designed to give you time in case you change your mind or need to retrieve any important information from your account. During this period, your data is safe and not accessible to others.</p>

  <h2 class="banner__title strong m-b-20"> <span>Permanent Data Deletion:</span> </h2>
  <p>After the 15-day deactivation period, your account and all associated data will be permanently deleted from our system. This includes:</p>
  <ul>
    <li>Personal information, such as your profile details.</li>
    <li>Any data, files, or records associated with your account.</li>
  </ul>
  <p>Once your data is deleted, it will not be recoverable. We encourage you to back up any important information before submitting a deletion request.</p>

  <h2 class="banner__title strong m-b-20"> <span>Reactivation Option (Within 15 Days):</span> </h2>
  <p>If you decide to reactivate your account during the 15-day deactivation period, you can contact our support team, and we will restore your access without any loss of data. However, after the 15-day period, reactivation will no longer be possible, as your data will be permanently removed.</p>

  <h2 class="banner__title strong m-b-20"> <span>Contact Us</span> </h2>
  <p>If you have any questions regarding the account deletion process or need assistance, please reach out to our support team at <a href="mailto:info@ibizi.com">info@ibizi.com</a>. We are here to help ensure a smooth and secure experience.</p>
       </div>
      <Footer/>
      <a href='#Home' className='scrolltopicon'>
        <img src={arrow} loading="lazy"  alt='sterick'/>
      </a>
    </>
   
  )
}
